import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography ,Grid,Paper} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useCookies} from 'react-cookie'

import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from '../../images/FMLogo.png'
import MenuIcon from '@mui/icons-material/Menu';

import './admin.css'
import D6_FE_AccountBox from './Login/D6_FE_AccountBox';
import Accordion_Blogs from './blogsAdmin';
import Accordion_Blogs_Site2 from './blogsAdmin _Site2';
import Accordion_Blogs_Site3 from './blogsAdmin_Site3';
export default function Admin(props) {

  const[auth,setAuth]=useCookies(['auth-fm'])

  const reloadFxn=()=>{
    props.reloadFxn()
  }
  const TagData=props.tagData
  const blogData=props.blogData
  const advData=props.adv



  const [state, setState] = React.useState({
 
    left: false,
   
  });
  const [screen,setScreen]=React.useState('Admin')
  const [anchor,setAnchor]=React.useState('left')

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <Divider />
      <List>
        {['Travel Tips','Brands Corner','Taxi Tour'].map((text, index) => (
          <ListItem key={text} onClick={()=>setScreen(text)} disablePadding>
            <ListItemButton>
             
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>

{auth&&auth['auth-fm']?  <React.Fragment >
          <CssBaseline />
      <AppBar
        position="fixed" 
        className="appbarMain"
       style={{top:120}}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
              <div >
            <IconButton
             onClick={()=>setState({...state,left:!state.left})}
                color="inherit"
                aria-label="open drawer"
                
                edge="start"
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{alignSelf:'center'}} >
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              {/* <div onClick={()=>window.location.href='/'} style={{width:'100%',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                BACK
              </div> */}
              <div style={{width:'100%',alignSelf:'center',justifyContent:'end',cursor:'pointer',display:'flex',flexDirection:'row'}} >
                
                
                <div onClick={()=>setScreen('Admin')} style={{paddingRight:40}}>
                BACK
                </div>
                <div onClick={()=>window.location.href='/'}  >
                HOME
                </div>
              </div>
              </div>
        </Toolbar>
      </AppBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}

          >

<Grid container  >
            <Grid style={{textAlign:'center'}} item xs='12'>
            <img src={Logo} style={{maxHeight:'90px',maxWidth:'90px',alignItems:'center', marginRight:'auto',}} />
              </Grid>
              <Grid  style={{textAlign:'center'}} item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto'}}>Hey<b style={{color:'#52B9D1'}}> fillymedia</b></h1>
              </Grid>

            
          <Grid item style={{alignSelf:'center',textAlign:'center'}} xs='4'>
          <IconButton               onClick={()=>setState({...state,left:!state.left})}
>
            {state.left ? <ChevronLeftIcon style={{fontSize: 40}} /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
                   
            {list(anchor)}
          </Drawer>
          <main
        
      >
        <div  />
        
    {
    screen==='Travel Tips'?<Accordion_Blogs blogData={blogData} TagData={TagData} advData={advData} reloadFxn={reloadFxn} />
    :screen==='Brands Corner'?<Accordion_Blogs_Site2 />:
    screen==='Taxi Tour'?<Accordion_Blogs_Site3/>:<div>
      <Grid style={{textAlign: '-webkit-center',marginTop:'235px'}} container>
      
       
        <Grid item   sm={3} xs={12}>
    <Paper onClick={()=>setScreen('Travel Tips')}  className='dash_sub'>
    Travel Tips
        </Paper>
        </Grid>
              
        <Grid item   sm={3} xs={12}>
    <Paper onClick={()=>setScreen('Brands Corner')}  className='dash_sub'>
    Brands Corner
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={12}>
    <Paper onClick={()=>setScreen('Taxi Tour')}  className='dash_sub'>
    Taxi Tour
        </Paper>
        </Grid>
   
  
        </Grid>
    </div>}
   
   

      </main>
        </React.Fragment>:<D6_FE_AccountBox />}
     
      
      
    </div>
  );
}
