import React, { useState,useEffect } from 'react';
// import { BlogsData } from '../homepage/homepageData';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import './admin.css'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Accordion, AccordionDetails, AccordionSummary, CardActionArea, Typography } from '@mui/material';
import {Grid} from "@mui/material";
import Chip from '@mui/material/Chip';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { apilink } from '../../App';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { MyEditorS } from '../blogs/editor';
import './admin.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const AccordionSection = styled.div`
margin-top:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/*  position: relative;  */
  height: 170vh;
  height: 100%;
 /* background: #fff;  */
  background: white;  
  z-index: 4;
  /*
  @media (max-width: 1200px) {
        height: 180vh ;
  }
  @media (max-width: 750px) {
        height: 110vh ;
  }
  @media (max-width: 660px) {
        height: 120vh ;
  }
  @media (max-width: 469px) {
        height: 135vh ;
  }
  @media (max-width: 430px) {
        height: 146vh ;
  }
  @media (max-width: 400px) {
        height: 154vh ;
  }
  @media (max-width: 350px) {
        height: 167vh ;
  }  */
`;

const Container = styled.div`
/*  position: absolute; */
  width: 100%;
  top: 30%;
/*  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);*/
`;

const Wrap = styled.div`
  background: #272727;
  background: white;
  color: #fff;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 1.3rem;
    text-align: left;
  }
  span {
    margin-right: 1.5rem;
  }
  @media (max-width: 750px) {
    h1 {
    font-size: 1.05rem;
    padding: 1rem;
    }
  }
  
`;

const Dropdown = styled.div`
  background: #1c1c1c;
  background: white;
  color: white;
  color: black;
  width: 100%;
  height: 130px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
 /* align-items: center; */
  border-bottom: 1px solid white;
  border-bottom: 1px solid var(--redcolor);
  border-top: 1px solid white;
  border-top: 1px solid var(--redcolor);
  p {
    padding: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
  }
  @media (max-width: 750px) {
    p {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    }
  }
  /*
  @media (max-width: 1200px) {
        height: 160px ;
    p {
    padding: 0rem 1rem;
    font-size: 1rem;
    }
  }
  @media (max-width: 750px) {
        height: 190px ;
  }
  @media (max-width: 652px) {
        height: 210px ;
  }
  @media (max-width: 600px) {
        height: 240px ;
  }
  @media (max-width: 540px) {
        height: 270px ;
  }
  @media (max-width: 480px) {
        height: 300px ;
  }
  @media (max-width: 440px) {
        height: 350px ;
  }
  @media (max-width: 400px) {
        height: 400px ;
  }
  @media (max-width: 357px) {
        height: 470px ;
  }   */
`;

const Accordion_Blogs_Site3 = (props) => {
  // const { blogData ,TagData,advData}=props

  const [blogData,setBlogData]=useState([])
  const [TagData,setTagData]=useState([])
  const [advData,setADVData]=useState([])


const [image,setImage]=useState('')
const [error,setError]=useState('')
const [edit,setEdit]=useState('')
const [open,setOpen]=useState(false)
const [processing,setProcessing]=useState(false)
const [newBlog,setNewBlog]=useState(false)
const [editor,setEditor]=useState('')
const[active,setActive]=useState('')
const [text,setText]=useState('')
const [newV,setNewV]=useState(false)
const [newV1,setNewV1]=useState(false)
const [newV2,setNewV2]=useState(false)

const [checked,setChecked]=useState(false)
const [checkedS,setCheckedS]=useState(false)
const [newADD,setNewADD]=useState(false)
const [reload,setReload]=useState(false)

const advertisement=advData.filter((val)=>val.site.includes('FM_Blogs3'))


const [openA,setOpenA]=useState('')





useEffect(() => {
  async function fetchApi(){
    fetch(apilink+'fm-tags-data3',
    { method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    },})
    .then(res => res.json())
    .then(resp=>setTagData(resp))
    .catch(err=>console.log(err))


  }
  fetchApi()
}, [reload])
useEffect(() => {
  async function fetchApi(){
    fetch(apilink+'fm-blog-data3',
    { method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    },})
    .then(res => res.json())
    .then(resp=>setBlogData(resp))
    .catch(err=>console.log(err))


  }
  fetchApi()
}, [reload])
useEffect(() => {
  async function fetchApi(){
    fetch(apilink+'fm-add-data',
    { method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    },})
    .then(res => res.json())
    .then(resp=>setADVData(resp))
    .catch(err=>console.log(err))


  }
  fetchApi()
}, [reload])

const [data,setData]=useState({
  head:'',
  createdBy:'',
  createdOn:'',
  shortHead:'',
  mainContent:'',
  image:'',
  urlName:'',metaKeywords:''
  ,metaDescription:'',
  metaTitle:''

})

const [open1,setOpen1]=useState(false)
const [filter,setFilter]=useState([])

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)

}


  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked === index) {
      return setClicked(null);
    }
setData('')
    setClicked(index);
  };

  const ImageCover1=(img)=>{
    
  
  
      
    if(img&&img.name)
  
      if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
    {
      
      if(img.size/1048576 <4){
  setData({...data,image:img})  
    }
       else{
         setError('File should be <4MB')
         setOpen(true)
       }
     } 
     else{
       setError('Upload jpg|jpeg|png format image')
       setOpen(true)
  
     }
   
  }





  const ImageCover=(img,val)=>{
    
    

    if(!edit&&img){
      setActive(val.id+'img')
      setEdit(true)
      
    if(img&&img.name)
  
      if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
    {
      
      if(img.size/1048576 <4){
  setImage(img)   
    }
       else{
         setError('File should be <4MB')
         setOpen(true)
       }
     } 
     else{
       setError('Upload jpg|jpeg|png format image')
       setOpen(true)
  
     }}
     if(edit&&!img&&active===(val.id+'img')){
       Save(val)
     }
     if(edit&&!img&&active!==(val.id+'img')){
       Cancel()
     }
     if(edit&&img&&active!==(val.id+'img')){
      Cancel()
    }
  }




  const ImageCoverAdd=(img,val)=>{
    
    

    if(!edit&&img){
      setActive(val.id+'img')
      setEdit(true)
      
    if(img&&img.name)
  
      if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
    {
      
      if(img.size/1048576 <4){
  setImage(img)   
    }
       else{
         setError('File should be <4MB')
         setOpen(true)
       }
     } 
     else{
       setError('Upload jpg|jpeg|png format image')
       setOpen(true)
  
     }}
     if(edit&&!img&&active===(val.id+'img')){
       SaveADD(val,'img')
     }
     if(edit&&!img&&active!==(val.id+'img')){
       Cancel()
     }
     if(edit&&img&&active!==(val.id+'img')){
      Cancel()
    }
  }




  const Cancel=()=>{
    setActive('')
    setImage('')
    setEditor('')

    setEdit(!edit)
    setCheckedS(false)
    setText('')
  }
  const Edit=(val,val1,val2,val4)=>{

   

    if(edit&&active===val1){
      Save(val)
    }
    else{
      if(val4){
        setEditor(val2)
      }
      setActive(val1)
      setText(val2)
      setImage('')
      setEdit(!edit)
    }


  }
  const reloadFxn=()=>{
   setReload(reload+1)
  }
  const Save=(val)=>{
    setProcessing(true)
    const formData=new FormData()
    formData.append(`${((val.id+val.valueHead)===active?'valueHead':(val.id+val.viewHead)===active?'viewHead':(val.id+val.createdBy)===active?'createdBy':(val.id+val.createdOn)===active?'createdOn':(val.id+val.urlName)===active?'urlName':(val.id+'img')===active&&image?'image':(val.id+val.views)===active?'views':(val.id+val.metaTitle)===active?'metaTitle':(val.id+val.metaDescription)===active?'metaDescription':(val.id+val.metaKeywords)===active?'metaKeywords':(val.id+val.active.toString())===active?'active':'value1')}`,(val.id+'img')===active&&image?image:(val.id+val.value1)===active?data.mainContent:(val.id+val.active.toString())===active&&checked?'1':(val.id+val.active.toString())===active&&!checked?'0':text)
    formData.append('id',val.id)


fetch(apilink+'fm-blog3',{
 method:'PUT',
 body:formData
  
}).then(resp=>resp.json())  
.then((res)=>respSave(res))  
  }
  const SaveB=(val)=>{
    if(val.category==='meta-title-blog'||val.category==='meta-description-blog'||val.category==='meta-keywords-blog'||val.category==='additionalImage'||val.category==='additionalUrl'){
      setProcessing(true)
      const formData=new FormData()
      formData.append('value',val.category==='additionalImage'?image:text)
      formData.append('id',val.id)
  
  
  fetch(apilink+'fm-tags3',{
   method:'PUT',
   body:formData
    
  }).then(resp=>resp.json())  
  .then((res)=>respSave(res))  
    }else{
      setProcessing(true)
    const formData=new FormData()
    formData.append('backgroundImage',image)
    formData.append('id',val.id)
    fetch(apilink+'fm-blog3',{
      method:'PUT',
      body:formData
       
     }).then(resp=>resp.json())  
     .then((res)=>respSave(res))  
       }

    }
    



  const respSave=(val)=>{
    setError(val.detail)
    setNewV1(false)
    setNewV(false)
    setNewV2(false)
    setEdit(false)
    setEditor('')
    setActive('')
    setImage('')
    setText('')
    setProcessing(false)

    if(val.status){

      
  
setOpen1(true)
reloadFxn()
}
if(!val.status){
 
setOpen(true)
}

  }
  const NewBlog=()=>{
    setData('')
    setEditor('')
    setNewBlog(false)
  }
  const saveNewBlog=()=>{
    if(newBlog){
if(data.image&&data.head&&data.shortHead&&data.createdBy&&data.createdOn&&data.urlName&&data.mainContent&&data.metaDescription&&data.metaTitle&&data.metaKeywords)


     { setProcessing(true)
      const formData=new FormData()
      formData.append('image',data.image)
      formData.append('valueHead',data.head)
      formData.append('viewHead',data.shortHead)
      formData.append('createdBy',data.createdBy)
      formData.append('createdOn',data.createdOn)
      formData.append('urlName',data.urlName)
      formData.append('value1',data.mainContent)
      formData.append('metaTitle',data.metaTitle)

      formData.append('metaDescription',data.metaDescription)
      formData.append('metaKeywords',data.metaKeywords)





  
  fetch(apilink+'fm-blog3',{
   method:'POST',
   body:formData
    
  }).then(resp=>resp.json())  
  .then((res)=>respBlog(res))  }
  else{
    setError('All Fields are necessary')
    setOpen(true)
  }
    }
    else{
      setNewBlog(true)
    }
  }

  const respBlog=(val)=>{
    setError(val.detail)
    setProcessing(false)
    if(val.status){
      setNewBlog(false)
      setOpen1(true)
      reloadFxn()

    }
    else{
      setNewBlog(false)

      setOpen(true)
    }
  }

  const quillChange=(val)=>{
   setData(data => ({ ...data, mainContent:val}))
   
  }

  const saveNewV=(val)=>{

    if(newV2&&val==='meta-title-blog'||newV&&val==='meta-description-blog'||newV1&&val==='meta-keywords-blog'){
      setProcessing(true)
    const formData=new FormData()
    formData.append('value',text)
    formData.append('category',val)


fetch(apilink+'fm-tags3',{
 method:'POST',
 body:formData
  
}).then(resp=>resp.json())  
.then((res)=>respSave(res))  
}else{
  if(val==='meta-description-blog'){
    setNewV(true)
    setNewV1(false)
    setNewV2(false)

  }
  if(val==='meta-title-blog'){
    setNewV2(true)
    setNewV1(false) 
    setNewV(false) 

  }
  if(val==='meta-keywords-blog'){
    setNewV1(true)
    setNewV2(false)

    setNewV(false)
  }
}
  }
  const Edit1=(val)=>{
    if(edit&&active===val.id){
     
      SaveB(val)
    }
    else{
      setActive(val.id)
      setText(val.value)
      setImage('')
      setEdit(!edit)
    }
  }
  const EditADD=(val,value,val_full)=>{
    if(edit&&active===val){
     
      SaveADD(val_full,'text')
    }
    else{
      setActive(val)
      setText(value)
      setImage('')
      setEdit(!edit)
    }
  }




  const SaveADD=(val,val1)=>{

 
      setProcessing(true)
      const formData=new FormData()
      formData.append('value',val1==='img'?image:text)
      formData.append('id',val.id)
      formData.append('type',val1==='img'?val1:active)
    



  
  
  fetch(apilink+'fm-add',{
   method:'PUT',
   body:formData
    
  }).then(resp=>resp.json())  
  .then((res)=>respSave(res))  
    


    }

const changeSwitch=(val,val1)=>{
setActive(val1.id+val1.active.toString())
setChecked(val)
setCheckedS(true)
setEdit(true)
}

const searchBar=(value1)=>{
  setFilter(blogData.filter((val,idx)=>((val.valueHead.toLowerCase().includes(value1.toLowerCase()))||(val.viewHead.toLowerCase().includes(value1.toLowerCase()))||(val.createdBy.toLowerCase().includes(value1.toLowerCase())
  )||(val.createdOn.includes(value1.toLowerCase()))||
  (val.views.toString().includes(value1))
  ||(val.urlName.toLowerCase().includes(value1.toLowerCase()))||(val.metaTitle.toLowerCase().includes(value1.toLowerCase())))).sort((a,b)=>a.id<b.id?-1:1))
}

const saveNewADD=()=>{
  if(newADD){
  if(data.image&&data.url)
  {
    setProcessing(true)
  const formData=new FormData()
      formData.append('image',data.image)
      formData.append('url',data.url)
      formData.append('site',"FM_Blogs3")
  
  fetch(apilink+'fm-add',
  {
    method:'POST',
    body:formData
  }).then(resp=>resp.json())  
  .then((res)=>respSave(res)) }
  else{
    setError('All Fields are necessary')
    setOpen(true)
  }}
  else{
    setNewADD(true)
  }
}
  return (
    <>
      <h1 style={{marginTop:'30vh',paddingLeft:'25px'}}>
          Blogs Sections
      </h1>
      <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <IconContext.Provider className="D6_FE_A_IC" value={{ color: 'var(--bcolor)', size: '25px' }}>
    
      <AccordionSection  className="D6_FE_AS">
       

    
  
        <Container className="D6_FE_Cont">
         
              <>
                <Wrap className="D6_FE_Wrap" onClick={() => toggle(2)}>
                  <h1 style={{color:'#000',fontWeight:'normal'}}>Blogs</h1>
                  <span>{clicked === 2 ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === 2 ? (
                  <Dropdown className="D6_FE_DD">
 

{newBlog?<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                            
                         <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                             Blog Heading
                           </Grid>
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Blog Heading" value={data.head} onChange={(e)=>setData({...data,head:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid> 
                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                             Blog Image
                           </Grid>
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <div  style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',width:'56%',marginTop:30,paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`fileNew`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image?'Uploaded':processing?'Saving':`Upload Image`}</label>
                  </div>

                  <input type={"file" } id={`fileNew`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0])}/><br />
       
                           </Grid>
                           </Grid> 
                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Created By
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Created By" value={data.createdBy} onChange={(e)=>setData({...data,createdBy:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>  

                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Created On
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Created On" value={data.createdOn} onChange={(e)=>setData({...data,createdOn:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>  
                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Blog Short Text
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Short Text" value={data.shortHead} onChange={(e)=>setData({...data,shortHead:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>  

                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Blog Url
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Blog Url" value={data.urlName} onChange={(e)=>setData({...data,urlName:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>  

                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Meta Title
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Meta Title" value={data.metaTitle} onChange={(e)=>setData({...data,metaTitle:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>
                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Meta Description
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Meta Description" value={data.metaDescription} onChange={(e)=>setData({...data,metaDescription:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>
                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                            Meta Keywords
                           </Grid>
                           
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Meta Keywords" value={data.metaKeywords} onChange={(e)=>setData({...data,metaKeywords:e.target.value})} variant="outlined" />
                           </Grid>
                           </Grid>

                         <Grid container justifyContent={'center'}>
                           <Grid item style={{marginTop:30,marginBottom:30}} >
                           <MyEditorS  quillChange={quillChange} editor={editor}/>
                           
</Grid>
                           </Grid>  
</Grid>:null}
<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                            <Chip onClick={()=>saveNewBlog()} label={newBlog&&!processing?'save':newBlog&&processing?'processing':'ADD NEW BLOG'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
         {newBlog?<Chip onClick={()=>NewBlog(!newBlog)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
        marginBottom: '20px'}}  />:null}
</Grid>
     {blogData[0]? <Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>



       
       
        <Grid  container sm={12} xs={12}>

        <Grid  style={{alignSelf:'center'}} item sm={12} xs={12}>
  
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20,marginBottom:20}} label="Search blog here" onChange={(e)=>searchBar(e.target.value)} variant="outlined" />
   
          </Grid>
        {(filter&&filter[0]?filter:blogData).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
  return(
    <Accordion  expanded={openA===idx} onClick={()=>setOpenA(idx)}  key={idx}>
    <AccordionSummary 
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
     <Typography style={{width:'80%',textAlign:'left'}}>{val1.urlName}</Typography>
      {val1.active?<div style={{width:'20%',textAlign:'-webkit-right',paddingRight:'5vw',alignSelf:'center'}}>
      <i class="fas fa-check-circle completed"></i>
          </div>:null}    </AccordionSummary>
    <AccordionDetails>
    <Grid style={{borderStyle: 'solid',
        paddingTop: '20px',
        paddingBottom: '20px',
        borderRadius: '20px',maxWidth:'100%',margin:10,maxHeight: '70vh',
        overflowY: 'scroll'}} container sm={12} xs={12}>
                 <Grid item style={{alignSelf:'center'}} sm={6} xs={4}>
                   
        <Typography>{val1.urlName}</Typography>
        </Grid>

        <Grid container style={{textAlign:'start',alignItems:'center'}} sm={6} xs={8}>
          <Grid item sm={6} xs={12}>
        <FormGroup  className='form_switch' >
      <FormControlLabel control={<Switch  checked={(checkedS&&(active===(val1.id+val1.active.toString()))?checked:val1.active)}  onChange={(e)=>changeSwitch(e.target.checked,val1)} />} label="Active" />
    </FormGroup>
        </Grid>
      {(active==(val1.id+val1.active.toString())&&checkedS)? <Grid item sm={6} xs={12}>
        <Chip onClick={()=>Edit(val1,val1.id+val1.active.toString(),val1.active.toString())}  label={edit&&active===(val1.id+val1.active.toString())&&processing?'Saving':'Save' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
           <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  
        </Grid>:null}
        </Grid>





        <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.valueHead)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.valueHead}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.valueHead,val1.valueHead)}  label={edit&&active===(val1.id+val1.valueHead)&&!processing?"Save":edit&&active===(val1.id+val1.valueHead)&&processing?'Saving':'Edit Blog Heading' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.valueHead)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
          </Grid>

          <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.createdBy)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.createdBy}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.createdBy,val1.createdBy)}  label={edit&&active===(val1.id+val1.createdBy)&&!processing?"Save":edit&&active===(val1.id+val1.createdBy)&&processing?'Saving':'Edit Created By' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.createdBy)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
          </Grid>

          <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.createdOn)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.createdOn}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.createdOn,val1.createdOn)}  label={edit&&active===(val1.id+val1.createdOn)&&!processing?"Save":edit&&active===(val1.id+val1.createdOn)&&processing?'Saving':'Edit Created On' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.createdOn)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
          </Grid>


          <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.views)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.views}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.views,val1.views)}  label={edit&&active===(val1.id+val1.views)&&!processing?"Save":edit&&active===(val1.id+val1.views)&&processing?'Saving':'Edit Views' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.views)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
          </Grid>



        <Grid container className='img_grid'  sm={12} xs={12}>
        <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
        <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={image&&active===(val1.id+'img')?URL.createObjectURL(image):(val1.image)}
alt="green iguana"
/>

</CardActionArea>
</Card>
 
        </Grid>
        <Grid style={{alignSelf:'center',paddingLeft:7}} container sm={6} xs={12}>


<Grid style={{alignSelf:'center',marginTop:'20px',marginBottom: '20px'}} item sm={6} xs={12}>

<div  onClick={()=>ImageCover('',val1)} style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`file${val1.id}`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{image&&edit&&active===(val1.id+'img')&&!processing?'Save':image&&edit&&active===(val1.id+'img')&&processing?'Saving':`Edit Image`}</label>
                  </div>

                  <input type={edit?'text':"file" } id={`file${val1.id}`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover(e.target.files[0],val1)}/><br />
         </Grid> 


{edit&&active===(val1.id+'img')?            <Grid style={{alignSelf:'center',marginBottom: '20px'}} item sm={6} xs={12}>

<Chip label="Cancel" onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px',cursor:'pointer'}}  />
</Grid>:null}

</Grid>
        </Grid>


        <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.viewHead)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.viewHead}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.viewHead,val1.viewHead)}  label={edit&&active===(val1.id+val1.viewHead)&&!processing?"Save":edit&&active===(val1.id+val1.viewHead)&&processing?'Saving':'Edit View Heading' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.viewHead)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
          </Grid>
          {val1.value1?<Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center',padding:10}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.value1)&&!editor?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
        edit&&active===(val1.id+val1.value1)&&editor?  <MyEditorS  quillChange={quillChange} editor={editor}/>
        :
          <Typography dangerouslySetInnerHTML={{__html:val1.value1}} style={{padding:' 0.5rem 2rem',fontSize:'1.1rem',fontWeight:'500',maxHeight:'40vh',overflowY:'scroll',border:'0.5px solid lightslategrey',borderRadius:20}}></Typography>}
   
          </Grid>
          
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.value1,val1.value1,'mainContent')}  label={edit&&active===(val1.id+val1.value1)&&!processing?"Save":edit&&active===(val1.id+val1.value1)&&processing?'Saving':`Edit Blog Main Content` } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.value1)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
         
          </Grid>:null}

        
         <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.urlName)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.urlName}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.urlName,val1.urlName)}  label={edit&&active===(val1.id+val1.urlName)&&!processing?"Save":edit&&active===(val1.id+val1.urlName)&&processing?'Saving':`Edit Blog Url` } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.urlName)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
         
          </Grid>

          <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.metaTitle)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.metaTitle}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.metaTitle,val1.metaTitle)}  label={edit&&active===(val1.id+val1.metaTitle)&&!processing?"Save":edit&&active===(val1.id+val1.metaTitle)&&processing?'Saving':`Edit Meta Title` } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.metaTitle)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
         
          </Grid>







          <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.metaDescription)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.metaDescription}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.metaDescription,val1.metaDescription)}  label={edit&&active===(val1.id+val1.metaDescription)&&!processing?"Save":edit&&active===(val1.id+val1.metaDescription)&&processing?'Saving':`Edit Meta Description` } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.metaDescription)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
         
          </Grid>
          <Grid  container sm={12} xs={12}>
  
  <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
  {edit&&active===(val1.id+val1.metaKeywords)?
         <TextField 
        id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
          <Typography>{val1.metaKeywords}</Typography>}
   
          </Grid>
  
         
          
          <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
          <Chip onClick={()=>Edit(val1,val1.id+val1.metaKeywords,val1.metaKeywords)}  label={edit&&active===(val1.id+val1.metaKeywords)&&!processing?"Save":edit&&active===(val1.id+val1.metaKeywords)&&processing?'Saving':`Edit Meta Keywords` } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
          {edit&&active===(val1.id+val1.metaKeywords)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
         :null }
          
          </Grid>
         
          </Grid>

        </Grid>
    </AccordionDetails>
  </Accordion> )})} 
        </Grid>
       
        </Grid >:null}
                  </Dropdown>
                ) : null}
              </>
        
        </Container>

        <Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(3)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Site Meta Tags</h1>
             <span>{clicked === 3 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 3 ? (
             <Dropdown className="D6_FE_DD">
  <Grid style={{textAlign:'-webkit-center',borderBottomStyle:'solid',paddingBottom:15 }} container sm={12} xs={12}>
<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Meta Title</Typography>
</Grid>
{newV2?<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />
</Grid>:null}

<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                       <Chip onClick={()=>saveNewV('meta-title-blog')} label={newV2&&!processing?'save':newV2&&processing?'processing':'ADD NEW TITLE'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
   marginBottom: '20px'}}  />
    {newV2?<Chip onClick={()=>setNewV2(!newV2)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
   marginBottom: '20px'}}  />:null}
</Grid>
{TagData.filter((val)=>val.category.includes('meta-title-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
 return( 
   <Grid  container key={idx} >
<Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   {edit&&active===valB.id?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{valB.value}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>Edit1(valB)}  label={edit&&active===valB.id&&!processing?"Save":edit&&active===valB.id&&processing?'Saving':'Edit Title' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===valB.id? <Chip label="Cancel"  onClick={()=>Cancel(valB.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>
 )})} 
 </Grid>
 <Grid style={{textAlign:'-webkit-center',borderBottomStyle:'solid',paddingBottom:15 }} container sm={12} xs={12}>
<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Meta Description</Typography>
</Grid>
{newV?<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />
</Grid>:null}

<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                       <Chip onClick={()=>saveNewV('meta-description-blog')} label={newV&&!processing?'save':newV&&processing?'processing':'ADD NEW DESCRIPTION'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
   marginBottom: '20px'}}  />
    {newV?<Chip onClick={()=>setNewV(!newV)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
   marginBottom: '20px'}}  />:null}
</Grid>
{TagData.filter((val)=>val.category.includes('meta-description-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
 return( 
   <Grid  container key={idx} >
<Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   {edit&&active===valB.id?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{valB.value}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>Edit1(valB)}  label={edit&&active===valB.id&&!processing?"Save":edit&&active===valB.id&&processing?'Saving':'Edit Description' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===valB.id? <Chip label="Cancel"  onClick={()=>Cancel(valB.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>
 )})} 
 </Grid>

 
 
 
 <Grid  style={{textAlign:'-webkit-center',paddingBottom:20 }} container sm={12} xs={12}>
<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Meta Keywords</Typography>
</Grid>
{newV1?<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />
</Grid>:null}

<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                       <Chip onClick={()=>saveNewV('meta-keywords-blog')} label={newV1&&!processing?'save':newV1&&processing?'processing':'ADD NEW KEYWORDS'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
   marginBottom: '20px'}}  />
    {newV1?<Chip onClick={()=>setNewV1(!newV1)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
   marginBottom: '20px'}}  />:null}
</Grid>


{TagData.filter((val)=>val.category.includes('meta-keywords-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
 return( 
   <Grid  container key={idx}>
<Grid style={{alignSelf:'center',textAlign:'-webkit-center',}} item sm={6} xs={12}>
   {edit&&active===valB.id?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{valB.value}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>Edit1(valB)}  label={edit&&active===valB.id&&!processing?"Save":edit&&active===valB.id&&processing?'Saving':'Edit Keywords' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===valB.id? <Chip label="Cancel"  onClick={()=>Cancel(valB.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
</Grid>)})} 
 </Grid>
 
  </Dropdown>
           ) : null}
         </>
   
   </Container>  


   <Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(4)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Blogs Additional Component</h1>
             <span>{clicked === 4 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 4 ? (
             <Dropdown className="D6_FE_DD">


{advertisement&&advertisement[0]?null:<Grid container style={{textAlign:'-webkit-center', }} >
{newADD?<><Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                             Advertisement
                           </Grid>
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

                           <div  style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',width:'56%',marginTop:30,paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                  <label  for={`fileNew`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image?'Uploaded':processing?'Saving':`Upload Image`}</label>
                  </div>

                  <input type={"file" } id={`fileNew`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0])}/><br />
       
                           </Grid>
                           </Grid> 
                           <Grid container>
                           <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                           Advertisement URL
                           </Grid>
                           <Grid item style={{textAlign:'start'}} xs={6} sm={6}>

<TextField 
id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="URL" value={data.url} onChange={(e)=>setData({...data,url:e.target.value})} variant="outlined" />
</Grid>
                           
                           </Grid></>:null}

                           <Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                            <Chip onClick={()=>saveNewADD()} label={newADD&&!processing?'save':newADD&&processing?'processing':'ADD ADVERTISEMENT'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
        marginBottom: '20px'}}  />
         {newADD?<Chip onClick={()=>setNewADD(!newADD)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
        marginBottom: '20px'}}  />:null}
</Grid>
</Grid>}

{advData&&advData[0]?(advData.filter((val)=>val.site.includes('FM_Blogs3')).map((val1,idx)=>{
 return(    
       <Grid  key={idx} container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
   <Grid style={{textAlign:'-webkit-center'}} item sm={6} xs={12}>
 
    <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={image?URL.createObjectURL(image):(val1.imageName)}
alt="green iguana"
/>

</CardActionArea>
</Card>
 
</Grid>
<Grid style={{alignSelf:'center'}} container sm={6} xs={12}>
<Grid style={{alignSelf:'center',marginTop:'20px'}} item sm={6} xs={12}>

<div onClick={()=>{ImageCoverAdd('',val1)}} style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                          <label  for="files1" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{image&&edit&&active===val1.id+'img'&&!processing?'Save':image&&edit&&active===val1.id+'img'&&processing?'Saving':'Edit Component Image'}</label>
                          </div>

                          <input type={edit?'text':"file" } id="files1" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCoverAdd(e.target.files[0],val1)}/><br />
                 </Grid> 
    {edit&&active===val1.id+'img'?             <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
  
<Chip label="Cancel" onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px',cursor:'pointer'}}  />
</Grid>:null}
</Grid>

<Grid style={{textAlign:'-webkit-center',borderBottomStyle:'solid',paddingBottom:15 }} container sm={12} xs={12}>
<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Component Url</Typography>
</Grid>




   <Grid  container >
<Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   {edit&&active===val1.id?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text"  value={text?text:val1.url} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{val1.url}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>EditADD(val1.id,val1.param_value,val1)}  label={edit&&active===val1.id&&!processing?"Save":edit&&active===val1.id&&processing?'Saving':'Edit Url' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===val1.id? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>


   <Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Navigation Param</Typography>
</Grid>




   <Grid  container >
<Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   {edit&&active===val1.id+'param'?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text"  value={text?text:val1.param} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{val1.param}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>EditADD(val1.id+'param',val1.param,val1)}  label={edit&&active===val1.id+'param'&&!processing?"Save":edit&&active===val1.id+'param'&&processing?'Saving':'Edit Param' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===val1.id+'param'? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>


   <Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Navigation Url</Typography>
</Grid>




   <Grid  container >
<Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   {edit&&active===val1.id+'url'?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text"  value={text?text:val1.param_value} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{val1.param_value}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>EditADD(val1.id+'url',val1.param_value,val1)}  label={edit&&active===val1.id+'url'&&!processing?"Save":edit&&active===val1.id+'url'&&processing?'Saving':'Edit Navigation Url' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===val1.id+'url'? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>

 </Grid>
    </Grid >

         )})):null}  


             </Dropdown>
           ) : null}
         </>
   
   </Container>
         
      </AccordionSection>
    </IconContext.Provider>
   

    </>
  );
};

export default Accordion_Blogs_Site3;