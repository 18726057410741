import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';


const TBI_PrivacyPolicy = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid1">
                <h3 className="TBI_PrivacyPolicy_Block_MH TBI_PrivacyPolicy_Block_H">
                Privacy Policy
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                Your privacy is very important for “Get Travel Tips” takes your privacy seriously. This privacy policy explains how we are collecting your personal information and using it privately without sharing it with anyone. Read below to learn more about our standard privacy policies.      </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>Personal Data Collection</b><br></br><br />

All basic information about our visitors will be tracked by all web servers. We track basic data which includes IP addresses, browser details, timestamps and referring pages, in addition to some more information. None of this information can personally identify specific visitors to this site. The information is tracked for routine administration and maintenance purposes. 
                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>Cookies and Web Beacons </b><br></br><br />

                We utilizes cookies to retain data about a visitor's preferences and history when it is essential to provide them with more personalized content and/or to better serve them. 

In order to show adverts and other helpful information, advertising partners and other third parties may also use cookies, scripts, and/or web beacons to monitor visitors to our site. These third parties do the direct tracking on their own servers and in accordance with their own privacy rules.                    </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>Personal Data Collection</b><br></br><br />

All basic information about our visitors will be tracked by all web servers. We track basic data which includes IP addresses, browser details, timestamps and referring pages, in addition to some more information. None of this information can personally identify specific visitors to this site. The information is tracked for routine administration and maintenance purposes. 
                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>Controlling Your Privacy</b><br></br><br />

                If your privacy is a concern, you may adjust your browser settings to deactivate cookies. It's not advised to disable cookies on all websites because doing so can make it difficult for you to utilise some of them. The best choice is to control cookie settings for each individual website. For advice on how to block cookies and other tracking technologies, go to the manual for your browser. This collection of links for managing privacy settings in web browsers may also be helpful. 

</p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>Special Note About Google Advertising </b><br></br><br />

                Cookies may be used to manage any advertisements provided by Google, Inc. and connected businesses. Based on your visits to this site and other websites that make use of Google's advertising services, Google is able to display adverts according to these cookies. Find out how to reject Google's use of cookies. As previously stated, Google's privacy rules apply to any tracking it does via cookies and other tools. 
</p>
            </Grid>
        
       
       </Grid> </div>

    

    )
}

export default TBI_PrivacyPolicy
